<template>
  <div class="card">
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-lg-4">
          <div class="input-icon mb-3">
            <input type="text" class="form-control" :placeholder="translations.administrators.search_placeholder" v-model.trim="search">
            <span class="input-icon-addon">
              <i class="fe fe-search"></i>
            </span>
          </div>
        </div>
        <div class="col-lg-8 text-right">
          <button class="btn btn-primary" @click="newAdministrator()">
            <i class="fe fe-plus mr-1"></i>
            Ajouter
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive mb-0">
      <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
        <thead>
          <tr style="white-space: pre; cursor: pointer;">
            <th class="pr-0" v-for="header in headers" :class="header.class" :key="header.key" @click="fetchOrder(header.order)" style="line-height: 1rem;">
              <span v-html="translations.administrators[`${header.key}`]"></span>
              <i v-show="header.order == orderKey" class="fa ml-1" :class="orderDirection == 'asc' ? 'fa-caret-up' : 'fa-caret-down'"></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="administrator in administrators" :key="administrator.id">
            <td>
              {{ administrator.first_name }} {{ administrator.last_name }}
            </td>
            <td class="text-center">
              <i
                v-if="administrator.root"
                class="fa fa-fw fa-check text-success">
              </i>
              <i
                v-else
                class="fa fa-fw fa-times text-muted">
              </i>
            </td>
            <td>
              {{ administrator.email }}
            </td>
            <td class="text-center">
              {{ administrator.created_at | date }}
            </td>
            <td class="text-center">
              {{ administrator.updated_at | date }}
            </td>
            <td class="text-right">
              <a class="icon" href="#" v-b-popover.hover.bottom="translations.administrators.edit_tooltip" @click.prevent="editAdministrator(administrator)">
                <i class="fe fe-edit"></i>
              </a>
              <a v-if="administrator.id != currentId" class="icon ml-2" href="#" v-b-popover.hover.bottom="translations.administrators.delete_tooltip" @click.prevent="deleteAdministrator(administrator)">
                <i class="fe fe-trash"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <p class="text-center" v-show="!busy && administrators.length == 0">
        {{ translations.administrators.empty_result }}
      </p>
      <b-pagination
        v-show="administrators.length > 0"
        class="my-2"
        v-model="page"
        :total-rows="pagination.total"
        :per-page="pagination.per"
        align="right"
        @input="fetch">
      </b-pagination>
    </div>
    <ValidationObserver ref="validationObserver" v-slot="{ invalid, dirty, pristine, touched }" tag="form">
      <b-modal ref="formModal" centered :title="formTitle" cancel-title="Annuler" ok-title="Valider" :ok-disabled="busy || invalid" @ok="submitAdministrator">
        <div class="form-group">
          <label class="form-label">
            Nom
            <span class="form-required">*</span>
          </label>
          <ValidationProvider name="last_name" rules="required" v-slot="{ invalid, classes, errors }">
            <input type="text" class="form-control" :class="invalid && classes" name="last_name" v-model.trim="formModel.last_name">
            <div class="invalid-feedback">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label class="form-label">
            Prénom
            <span class="form-required">*</span>
          </label>
          <ValidationProvider name="first_name" rules="required" v-slot="{ invalid, classes, errors }">
            <input type="text" class="form-control" :class="invalid && classes" name="first_name" v-model.trim="formModel.first_name">
            <div class="invalid-feedback">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label class="form-label">
            Email
            <span class="form-required">*</span>
          </label>
          <ValidationProvider name="email" rules="required" v-slot="{ invalid, classes, errors }">
            <input type="email" class="form-control" :class="invalid && classes" name="email" v-model.trim="formModel.email">
            <div class="invalid-feedback">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="form-group mb-0">
          <label>
            <input type="checkbox" name="root" class="custom-switch-input" v-model="formModel.root" :disabled="currentAdministrator && currentAdministrator.id == currentId">
            <span class="custom-switch-indicator"></span>
            <span class="custom-switch-description">Peut gérer les autres administrateurs</span>
          </label>
        </div>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import { BPagination, VBPopover,  } from 'bootstrap-vue'
  import { extend, configure, ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
  import Date from '../filters/Date'

  extend('required', {
    ...required,
    message: 'Ce champ est obligatoire.'
  })
  extend('email', {
    ...email,
    message: 'Email invalide.'
  })
  configure({
    classes: {
      valid: 'is-valid',
      invalid: 'is-invalid'
    }
  })
  export default {
    components: {
      BPagination,
      ValidationProvider,
      ValidationObserver
    },
    directives: {
      'b-popover': VBPopover
    },
    filters: {
      Date
    },
    props: {
      translations: Object,
      src: String,
      currentId: Number
    },
    data() {
      return {
        administrators: () => [],
        orderKey: 'administrators.created_at',
        orderDirection: 'desc',
        pagination: {},
        search: '',
        page: 1,
        busy: false,
        headers: [{
          key: 'administrator_name_column',
          order: 'administrators.last_name',
          class: 'text-left'
        }, {
          key: 'administrator_root_column',
          order: 'administrators.root',
          class: 'text-center'
        }, {
          key: 'administrator_email_column',
          order: 'administrators.email',
          class: 'text-left'
        }, {
          key: 'administrator_created_at_column',
          order: 'administrators.created_at',
          class: 'text-center'
        }, {
          key: 'administrator_updated_at_column',
          order: 'administrators.updated_at',
          class: 'text-center'
        }],
        formTitle: null,
        formModel: {},
        currentAdministrator: null
      }
    },
    mounted() {
      this.fetch()
    },
    methods: {
      fetch() {
        if (this.busy) return
        this.busy = true
        axios.get(
          this.src, {
            params: {
              search: this.search,
              order: this.orderKey,
              direction: this.orderDirection,
              page: this.page
            }
          }
        ).then(response => {
          this.administrators = response.data.administrators
          this.pagination = response.data.pagination
          this.busy = false
        })
      },
      fetchOrder(attribute) {
        if (this.orderKey == attribute) {
          this.orderDirection = (this.orderDirection == 'asc' ? 'desc' : 'asc')
        } else {
          this.orderKey = attribute
        }
        this.fetch()
      },
      newAdministrator() {
        this.currentAdministrator = null
        this.formTitle = "Nouveau compte administrateur"
        this.formModel = {
          first_name: null,
          last_name: null,
          email: null,
          root: false
        }
        this.$nextTick(() => this.$refs.formModal.show())
      },
      editAdministrator(administrator) {
        this.currentAdministrator = administrator
        this.formMode = 'update'
        this.formTitle = "Modification compte administrateur"
        this.formModel = {
          first_name: administrator.first_name,
          last_name: administrator.last_name,
          email: administrator.email,
          root: administrator.root
        }
        this.$nextTick(() => this.$refs.formModal.show())
      },
      submitAdministrator(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.busy = true
        let url, method
        if (this.currentAdministrator) {
          method = 'patch'
          url = this.currentAdministrator.url
        } else {
          method = 'post'
          url = this.src
        }
        axios({
          url: url,
          method: method,
          data: {
            administrator: {
              first_name: this.formModel.first_name,
              last_name: this.formModel.last_name,
              email: this.formModel.email,
              root: this.formModel.root
            }
          }
        }).then((response) => {
          this.busy = false
          this.$nextTick(() => this.$refs.formModal.hide())
          this.fetch()
        }).catch((error) => {
          this.busy = false
          this.$refs.validationObserver.setErrors(error.response.data)
        })
      },
      deleteAdministrator(administrator) {
        let message = `Supprimer le compte administrateur de ${administrator.first_name} ${administrator.last_name} ? Attention, cette opération est irréversible.`
        this.$bvModal.msgBoxConfirm(message, {
          title: "Suppression d'un administrateur",
          okVariant: 'danger',
          okTitle: "Supprimer le compte",
          cancelTitle: "Annuler",
          hideHeaderClose: true,
          centered: true
        }).then(confirmed => {
          if (confirmed) {
            this.busy = true
            axios.delete(
              administrator.url
            ).then(response => {
              this.busy = false
              this.fetch()
            })
          }
        })
      }
    },
    watch: {
      search: _.debounce(function() {
        this.page = 1
        this.fetch()
      }, 200)
    }
  }
</script>
