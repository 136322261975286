<template>
  <div class="card">
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-lg-4">
          <div class="input-icon mb-3">
            <input type="text" class="form-control" :placeholder="translations.users.search_placeholder" v-model.trim="search">
            <span class="input-icon-addon">
              <i class="fe fe-search"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mb-0">
      <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
        <thead>
          <tr style="white-space: pre; cursor: pointer;">
            <th class="w-1"></th>
            <th class="pr-0" v-for="header in headers" :class="header.class" :key="header.key" @click="fetchOrder(header.order)" style="line-height: 1rem;">
              <span v-html="translations.users[`${header.key}`]"></span>
              <i v-show="header.order == orderKey" class="fa ml-1" :class="orderDirection == 'asc' ? 'fa-caret-up' : 'fa-caret-down'"></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="site in sites" :key="site.id">
            <td>
              <div class="avatar d-block" :style="{ 'background-image': `url(${site.user.avatar_url})` }"></div>
            </td>
            <td>
              <div>{{ site.user.first_name }} {{ site.user.last_name }}</div>
              <div class="small text-muted">{{ site.user.email }}</div>
            </td>
            <td class="text-capitalize">
              {{ site.user.origin }}
            </td>
            <td class="text-center">
              <label class="custom-switch pl-0">
                <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" v-model="site.user.internal" @change="changeUserInternal(site.user)">
                <span class="custom-switch-indicator"></span>
              </label>
            </td>
            <td class="text-center">
              <i class="fa fa-fw" :class="site.completed ? 'fa-check text-success' : 'fa-times text-danger'"></i>
            </td>
            <td class="text-center">
              <i
                v-if="site.subscription && site.subscription.status == 'activated'"
                class="fa fa-fw fa-check text-success"
                v-b-popover.hover.bottom="translations.users.subscription_activated_tooltip">
              </i>
              <i
                v-else-if="site.subscription && site.subscription.status == 'expired'"
                class="fa fa-fw fa-credit-card text-danger"
                v-b-popover.hover.bottom="translations.users.expired_payment_tooltip">
              </i>
              <i
                v-else-if="site.subscription && site.subscription.status == 'refused'"
                class="fa fa-fw fa-credit-card text-danger"
                v-b-popover.hover.bottom="translations.users.refused_payment_tooltip">
              </i>
              <i
                v-else
                class="fa fa-fw fa-times text-muted"
                v-b-popover.hover.bottom="translations.users.subscription_pending_tooltip">
              </i>
            </td>
            <td class="text-center">
              <a v-if="site.status == 'published'" :href="site.front_url" target="_blank">
                <i
                  class="fa fa-fw fa-check text-success"
                  v-b-popover.hover.bottom="translations.users.live_tooltip">
                </i>
              </a>
              <i
                v-else-if="site.status == 'locked'"
                class="fa fa-fw fa-ban text-danger"
                v-b-popover.hover.bottom="translations.users.locked_tooltip">
              </i>
              <i
                v-else
                class="fa fa-fw fa-times text-muted"
                v-b-popover.hover.bottom="translations.users.pending_tooltip">
              </i>
            </td>
            <td class="text-center">
              {{ site.user.created_at | date }}
            </td>
            <td class="text-center">
              <span v-if="site.subscribed_at">
                {{ site.subscribed_at | date }}
              </span>
            </td>
            <td class="text-center">
              {{ site.updated_at | date }}
            </td>
            <td class="text-center">
              <label class="custom-switch pl-0">
                <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" v-model="site.locked" @change="changeSiteStatus(site)">
                <span class="custom-switch-indicator"></span>
              </label>
            </td>
            <td class="text-right">
              <a class="icon mr-1" href="#" v-b-popover.hover.bottom="translations.users.reset_tooltip" @click.prevent="resetSite(site)">
                <i class="fe fe-rotate-ccw"></i>
              </a>
              <a class="icon" href="#" v-b-popover.hover.bottom="translations.users.delete_tooltip" @click.prevent="deleteUser(site.user)">
                <i class="fe fe-trash"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <p class="text-center" v-show="!busy && sites.length == 0">
        {{ translations.users.empty_result }}
      </p>
      <b-pagination
        v-show="sites.length > 0"
        class="my-2"
        v-model="page"
        :total-rows="pagination.total"
        :per-page="pagination.per"
        align="right"
        @input="fetch">
      </b-pagination>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import { BPagination, VBPopover } from 'bootstrap-vue'
  import Date from '../filters/Date'

  export default {
    components: {
      BPagination
    },
    directives: {
      'b-popover': VBPopover
    },
    filters: {
      Date
    },
    props: {
      translations: Object,
      src: String
    },
    data() {
      return {
        sites: () => [],
        orderKey: 'sites.created_at',
        orderDirection: 'desc',
        pagination: {},
        search: '',
        page: 1,
        busy: false,
        headers: [{
          key: 'user_column',
          order: 'users.last_name',
          class: 'text-left'
        }, {
          key: 'type_column',
          order: 'users.remote_service',
          class: 'text-left'
        }, {
          key: 'internal_column',
          order: 'users.internal',
          class: 'text-center'
        }, {
          key: 'completed_column',
          order: 'sites.completed_at',
          class: 'text-center'
        }, {
          key: 'subscription_column',
          order: 'subscriptions_sites.status',
          class: 'text-center'
        }, {
          key: 'published_column',
          order: 'sites.status',
          class: 'text-center'
        }, {
          key: 'created_at_column',
          order: 'users.created_at',
          class: 'text-center'
        }, {
          key: 'subscribed_at_column',
          order: 'sites.subscribed_at',
          class: 'text-center'
        }, {
          key: 'updated_at_column',
          order: 'sites.updated_at',
          class: 'text-center'
        }, {
          key: 'lock_column',
          order: 'sites.status',
          class: 'text-center'
        }]
      }
    },
    mounted() {
      this.fetch()
    },
    methods: {
      fetch() {
        if (this.busy) return
        this.busy = true
        axios.get(
          this.src, {
            params: {
              search: this.search,
              order: this.orderKey,
              direction: this.orderDirection,
              page: this.page
            }
          }
        ).then(response => {
          this.sites = response.data.sites
          this.pagination = response.data.pagination
          this.busy = false
        })
      },
      fetchOrder(attribute) {
        if (this.orderKey == attribute) {
          this.orderDirection = (this.orderDirection == 'asc' ? 'desc' : 'asc')
        } else {
          this.orderKey = attribute
        }
        this.fetch()
      },
      deleteUser(user) {
        const message = this.$root.interpolate(this.translations.delete_modal.message, {
          name: `${user.first_name} ${user.last_name}`
        })
        const title = this.$root.interpolate(this.translations.delete_modal.title, {
          name: `${user.first_name} ${user.last_name}`
        })
        this.$bvModal.msgBoxConfirm(message, {
          title: title,
          okVariant: 'danger',
          okTitle: this.translations.delete_modal.delete_button,
          cancelTitle: this.translations.delete_modal.cancel_button,
          hideHeaderClose: true,
          centered: true
        }).then(confirmed => {
          if (confirmed) {
            axios.delete(
              user.url
            ).then(response => {
              this.fetch()
            })
          }
        })
      },
      resetSite(site) {
        const message = this.$root.interpolate(this.translations.reset_modal.message, {
          name: `${site.user.first_name} ${site.user.last_name}`
        })
        this.$bvModal.msgBoxConfirm(message, {
          title: this.translations.reset_modal.title,
          okVariant: 'danger',
          okTitle: this.translations.reset_modal.reset_button,
          cancelTitle: this.translations.reset_modal.cancel_button,
          hideHeaderClose: true,
          centered: true
        }).then(confirmed => {
          if (confirmed) {
            axios.post(
              site.reset_url
            ).then(response => {
              let index = _.findIndex(this.sites, { id: site.id })
              this.$set(this.sites, index, response.data.site)
            })
          }
        })
      },
      changeSiteStatus(site) {
        let message, confirm, method
        if (site.locked) {
          message = this.$root.interpolate(this.translations.lock_modal.lock_message, {
            name: `${site.user.first_name} ${site.user.last_name}`
          })
          confirm = this.translations.lock_modal.lock_button
          method = 'post'
        } else {
          message = this.$root.interpolate(this.translations.lock_modal.unlock_message, {
            name: `${site.user.first_name} ${site.user.last_name}`
          })
          confirm = this.translations.lock_modal.unlock_button
          method = 'delete'
        }
        this.$bvModal.msgBoxConfirm(message, {
          title: this.translations.lock_modal.title,
          okVariant: 'danger',
          okTitle: confirm,
          cancelTitle: this.translations.lock_modal.cancel_button,
          hideHeaderClose: true,
          centered: true
        }).then(confirmed => {
          if (confirmed) {
            axios({
              url: site.lock_url,
              method: method
            }).then(response => {
              let index = _.findIndex(this.sites, { id: site.id })
              this.$set(this.sites, index, response.data.site)
            })
          } else {
            site.locked = !site.locked
          }
        })
      },
      changeUserInternal(user) {
        axios.patch(user.url, {
          user: {
            internal: user.internal
          }
        })
      }
    },
    watch: {
      search: _.debounce(function() {
        this.page = 1
        this.fetch()
      }, 200)
    }
  }
</script>
